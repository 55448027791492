import React from 'react';
import CommonHeader from './../components/CommonHeader';
import AboutUs from './../components/AboutUs';
import CreativityForEternity from './CreativityForEternity';
import Footer from './../components/Footer';

const AboutUsPage = () => {
  return (
    <div>
        <CommonHeader />
        <AboutUs />
        <CreativityForEternity />
        <Footer />
    </div>
  );
};

export default AboutUsPage;