// ParallaxSection.jsx
import React from 'react';
import './../css/ParallaxSection.css'; // Import the CSS file

const ParallaxSection = () => {
  return (
    <div className="parallax-container">
      <div className="parallax-content">
      </div>
    </div>
  );
};

export default ParallaxSection;
