import React from 'react';
import CommonHeader from './../components/CommonHeader';
import ContactUs from './../components/ContactUs';
import CreativityForEternity from './CreativityForEternity';
import Footer from './../components/Footer';

const ContactUsPage = () => {
  return (
    <div>
        <CommonHeader />
        <ContactUs />
        <CreativityForEternity />
        <Footer />
    </div>
  );
};

export default ContactUsPage;